/** Resize the font size of all elements with the class "fs-100" to fit its container.  */
export function resizeFonts() {
    $(".fs-100:visible").each(function () {
        resizeFont($(this));
    });
}

/**
 * Resize the font size of the element to fit its container.
 *
 * @param {any|jQuery|HTMLElement} element
 */
export function resizeFont(element) {
    const parentElement = element.parent();
    /* Fix the parent container height */
    parentElement.css("height", parentElement.height());

    /* Get the container's inner width and height */
    const containerWidth = element.width();
    const containerHeight = element.height();

    /* Wrap the content and icon in a temporary span */
    const $span = $("<span/>").html(element.html()).css({
        "display": "inline-block",
        "white-space": "nowrap",
        "visibility": "hidden",
        "position": "absolute",
    }).appendTo(element);

    let fontSize = parseInt(element.css("font-size"), 10);

    /* Increase font size until the span is too big for the container */
    while ($span.width() <= containerWidth && $span.height() <= containerHeight && fontSize < 100) {
        fontSize++;
        $span.css("font-size", fontSize + "px");
    }

    /* Decrease font size if the span is larger than the container */
    while (($span.width() > containerWidth || $span.height() > containerHeight) && fontSize > 1) {
        fontSize--;
        $span.css("font-size", fontSize + "px");
    }

    element.css("font-size", fontSize + "px", "important");

    /* Clean up: remove the temporary span */
    $span.remove();
}

/** Initializes the resize font observer. */
function initResizeFontObserver() {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                resizeFont($(entry.target));
            }
        });
    });

    const resizeFontElements = $(".fs-100");
    resizeFontElements.each(function () {
        observer.observe(this);
    });
    resizeFontElements.on("resize", function () {
        resizeFont($(this));
    });
}

initResizeFontObserver();

$(window).on("resize", function () {
    resizeFonts();
});
